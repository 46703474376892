import axios from "axios";
import store from "../store";
import {displayError, optionsLoading} from "../util/helpers";
import {ElLoading} from "element-plus";

const clientHttp = axios.create({
    baseURL: process.env.VUE_APP_URL,
    withCredentials: false, // required to handle the CSRF token
});
clientHttp.interceptors.response.use(
    (response) => {
        ElLoading.service().close();
        return response;
    },
    function (error) {
        ElLoading.service().close();
        displayError(error)
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters["auth/authUser"] &&
            !store.getters["auth/guest"]
        ) {
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    }
)
clientHttp.interceptors.request.use(function(config) {
    const auth_token = sessionStorage.authUser;
    if(auth_token) {
        config.headers.Authorization = `Bearer ${auth_token}`;
    }
    ElLoading.service(optionsLoading())
    return config;
}, function(err) {
    ElLoading.service().close();
    displayError(err)
    return Promise.reject(err);
});

export default clientHttp;