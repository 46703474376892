<template>
  <el-drawer :title="drawer.title"
             v-model="drawer.view"
             direction="rtl"
             :close-on-press-escape="false"
             :destroy-on-close="true"
             size="50%"
  >
    <Form key="frmState" @submit="onSubmit" :validation-schema="schema" class="pl-4 pr-4" v-if="!(model.action==='summary')">
      <div class="row justify-content-end mb-4">
        <div class="col-md-12 text-right">
        <base-button size="md" outline type="danger" @click="close" v-if="!model.disabled">
          <i class="el el-icon-close"></i>Close
        </base-button>

        <base-button native-type="submit" type="primary" size="md" v-if="!model.disabled"><i
            class="fa fa-save"></i>{{ model.action === 'save' ? 'Create' : 'Save' }}
        </base-button>
        <base-button native-type="button" type="primary" size="md" v-if="model.disabled" @click="edit()"><i
            class="fa fa-edit"></i>Edit
        </base-button>
        </div>
      </div>
      <div class="row" v-if="(model.action==='show' || model.action==='edit'|| model.action==='summary')">
        <div class="col-md-6">
          <base-input
              label="STATE ID" name="id"
              v-model="model.id" :value="model.id" :disabled="true"/>
        </div>
        <div class="col-md-6">
          <base-input
              label="CREATED AT" name="created_at"
              v-model="model.created_at" :value="model.created_at" :disabled="true"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
              label="STATE NAME" placeholder="Enter State Name" name="name"
              v-model="model.name" :value="model.name" :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input
              label="CODE" placeholder="Enter Code" name="abbreviation"
              v-model="model.abbreviation" :value="model.abbreviation" :disabled="model.disabled"/>
        </div>
      </div>
    </Form>
  </el-drawer>
</template>

<script>
import {Form} from "vee-validate";
import * as Yup from "yup";
import StateService from "../../../services/StateService";
import swal from "sweetalert2";
import moment from "moment";
import {ElOption, ElSelect} from "element-plus";

export default {
  name: "State",
  components: {
    Form,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      model: {
        action: 'save',
        disabled: false,
        email: '',
        phone: '',
        supervisor: '',
        city: '',
        address: '',
        name: '',
        id: '0',
        created_at: null
      },
      drawer: {
        title: '',
        view: false
      },
      form: {
        waiting: false
      },
      schema: null,
      summaryData: [],
      states:[],
      totalSummary: 0
    }
  },
  methods: {
    formatter: function (value) {
      const item=Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return item.format(value)
    },
    onSubmit(values, actions) {
      if (this.model.action === 'save') {
        StateService.save(values).then(() => {
          actions.resetForm();
          this.emitter.emit('listPaginateStates')
          this.close()
          swal.fire({
            title: `State created successful`,
          });
        });
      } else {
        StateService.edit(values.id, values).then(() => {
          actions.resetForm();
          this.emitter.emit('listPaginateStates')
          this.close()
          swal.fire({
            title: `State saved successful`,
          });
        });
      }
    },
    close() {
      this.initialize()
      this.drawer.view = false;
    },
    info() {
      this.drawer.title = 'See State';
      this.model.action = 'edit';
    },
    add() {
      this.initialize();
      this.drawer.title = 'Add State';
      this.drawer.view = true;
    },
    edit() {
      this.model.disabled = false;
      this.drawer.title = 'Edit State';
      this.model.action = 'edit';
    },
    show(row) {
      this.model.disabled = true;
      this.drawer.title = 'See State';
      this.model.created_at = moment(row.created_at).format('DD-MM-YYYY');
      this.model.abbreviation = row.abbreviation;
      this.model.name = row.name;
      this.model.id = row.id;
      this.drawer.view = true;
      this.model.action = 'show';
    },
    initialize() {
      this.model = {
        disabled: false,
        abbreviation: '',
        name: '',
        id: '0',
        created_at: null,
        action: 'save'
      };
    }
  },
  created() {
    this.emitter.on('addStates', () => this.add());
    this.emitter.on('showStates', (row) => this.show(row));
    this.schema = Yup.object().shape({
      abbreviation: Yup.string().max(250).required().label("The Code"),
      name: Yup.string().max(120).required().label("The State Name"),
    });
  },
  mounted() {
    
  }
}
</script>

<style>
header#el-drawer__title {
  font-weight: 900 !important;
}

.border-summary {
  font-weight: 900;
  font-size: larger;
  border: 1px solid;
  padding-left: 6px;
  padding-right: 6px;
}

.cell-color {
  background-color: #f6f9fc !important;
  color: #8898aa !important;
}
</style>