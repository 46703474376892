<template>
  <div class="wrapper">

    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Home',
            icon: 'ni ni-shop text-primary',
            path: '/home'
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Orders',
            icon: 'ni ni-ungroup',
            path: '/orders'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'States',
            icon: 'ni ni-ui-04 text-info',
            path: '/states'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Districts',
            icon: 'ni ni-ui-04 text-info',
            path: '/districts'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Vendors',
            icon: 'ni ni-single-copy-04 text-pink',
            path: '/vendors'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Products',
            icon: 'ni ni-archive-2 text-green',
            path: '/products',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  name: "DashboardLayout",
  components: {
    DashboardNavbar,
    ContentFooter,

  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    }
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
.title-route{
  font-weight: 900!important;
  font-size: x-large!important;
  color: #32325d!important;
}
</style>
