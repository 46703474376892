import clientHttp from "../axios";

export default {
    async list(params) {
        return clientHttp.get("/api/districts",{params:params});
    },
    async delete(id) {
        return clientHttp.delete(`/api/districts/${id}`);
    },
    async save(params) {
        return clientHttp.post(`/api/districts`,params);
    } ,
    async edit(id,params) {
        return clientHttp.put(`/api/districts/${id}`,params);
    },
    async summary(id){
        return clientHttp.post(`/api/reports/district`,{id})
    },
    async csv(id){
        return clientHttp.post(`/api/csv/district`,{id})
    },
    async listStates() {
        return clientHttp.get("/api/guest/states");
    }
};
