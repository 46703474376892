<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <Form Form @submit="onSubmit" :validation-schema="schema">
            <base-input
                name="email"
                formClasses="input-group-alternative mb-3"
                placeholder="Email"
                addon-left-icon="ni ni-email-83"
            >
            </base-input>

            <base-input
                name="password"
                formClasses="input-group-alternative mb-3"
                placeholder="Password"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
            >
            </base-input>

            <div class="text-center">
              <base-button native-type="submit" type="primary" class="my-4 btn-block">Login</base-button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Form} from "vee-validate";
import * as Yup from "yup";
import {mapGetters} from "vuex";
import AuthService from "../../../services/AuthService";
import store from "../../../store";
import router from "../../../router";

export default {
  name: "login",
  components: {
    Form
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    sessionStorage.removeItem('authUser');
  },
  setup() {
    function onSubmit(values) {
      AuthService.login(values).then(async (response) => {
        await sessionStorage.setItem('authUser', response.data.data.access_token);
        await store.dispatch("auth/setGuest", {value: "isNotGuest"});
        await router.push("/home");
      });
    }

    const schema = Yup.object().shape({
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(5).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };

  },
};
</script>
<style></style>
