<template>
  <router-view></router-view>
</template>

<script>
export default {};

</script>
<style>
.el-icon-loading{
  font-size: 50px!important;
  color: #2aceab!important;
  font-weight: 700!important;
}
.el-notification__content>p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

</style>