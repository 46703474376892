import clientHttp from "../axios";

export default {
  async login(payload) {
   // await authClient.get("/sanctum/csrf-cookie");
    return clientHttp.post("/api/auth/login", payload);
  },
  logout() {
    return clientHttp.post("/api/auth/logout");
  },
  async getAuthUser() {
    return clientHttp.post("/api/my-info");
  }
};
