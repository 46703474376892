<template>
  <div class="custom-file">
    <input
      type="file"
      class="custom-file-input"
      id="fileVideo"
      name="fileVideo"
      ref="fileVideo"
      lang="en"
      v-bind="$attrs"
      v-on="listeners"
      :accept="accept"
    />
    <label class="custom-file-label" for="fileVideo">
      {{ label }}
    </label>
  </div>
</template>
<script>
import {ElNotification} from "element-plus";

export default {
  name: "file-input-video",
  inheritAttrs: false,
  props: {
    initialLabel: {
      type: String,
      default: "Select file",
    },
    accept: {
      type: String,
      default: "application/mp4",
    },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    listeners() {
      return {
        change: this.fileChange,
      };
    },
    label() {
      let fileNames = [];
      for (let file of this.files) {
        fileNames.push(file.name);
      }
      return fileNames.length ? fileNames.join(", ") : this.initialLabel;
    },
  },
  methods: {
    fileChange(evt) {
      if(this.fileValidation( evt.target.files)){
        this.files = evt.target.files;
        this.$emit("change", this.files);
      }
    },

    fileValidation(file) {
      var fileInput = file[0];

      var filePath = fileInput.type;
      var  allowedExtensions= /(video\/mp4)$/i;
      if (!allowedExtensions.exec(filePath)) {
        ElNotification({
          type: 'error',
          title: 'Error',
          message: 'Files accepted: MP4'
        });
        return false;
      }
      var FileSize = fileInput.size / 1024 / 1024; // in MiB
      if (FileSize > 20) {
        ElNotification({
          type: 'error',
          title: 'Error',
          message: 'Max size file 20MB'
        });
        return false;
      }
      return true;

    }
  },
};
</script>
<style></style>
