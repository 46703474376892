<template>
  <el-drawer :title="drawer.title"
             v-model="drawer.view"
             direction="rtl"
             :close-on-press-escape="false"
             :destroy-on-close="true"
             size="45%">

    <div class="row ml-4" v-if="( model.action!=='save')">
      <Form @submit="info" class="m-2">
        <base-button size="sm" :outline="!(model.action==='edit' || model.action==='show')" type="primary"
                     native-type="submit">
          Vendor Info
        </base-button>
      </Form>
      <Form @submit="summary" class="m-2">
        <base-button size="sm" native-type="submit" :outline="!(model.action==='summary')" type="primary" nati>
          Order Summary
        </base-button>
      </Form>
    </div>

    <Form @submit="onSubmit" :validation-schema="schema" class="pl-4 pr-4" v-if="!(model.action==='summary')">
      <div class="row justify-content-end mb-4">
        <div class="col-md-12 text-right">
          <base-button size="md" outline type="danger" @click="close" v-if="!model.disabled">
            <i class="el el-icon-close"></i>Close
          </base-button>
          <base-button native-type="submit" type="primary" size="md" v-if="!model.disabled"><i
              class="fa fa-save"></i>{{ model.action === 'save' ? 'Create' : 'Save' }}
          </base-button>
          <base-button native-type="button" type="primary" size="md" v-if="model.disabled" @click="edit()"><i
              class="fa fa-edit"></i>Edit
          </base-button>
        </div>
      </div>
      <div class="row" v-if="(model.action==='show' || model.action==='edit'|| model.action==='summary')">
        <div class="col-md-6">
          <base-input
              label="VENDOR ID" name="id"
              v-model="model.id" :value="model.id" :disabled="true"/>
        </div>
        <div class="col-md-6">
          <base-input
              label="CREATED AT" name="created_at"
              v-model="model.created_at" :value="model.created_at" :disabled="true"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
              label="VENDOR NAME" placeholder="Enter Vendor Name" name="name"
              v-model="model.name" :value="model.name" :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="form-control-label">ADDRESS</label>
        </div>
        <div class="col-md-6">
          <base-input placeholder="First Avenue" name="address_avenue_one" :value="model.address_avenue_one"
                      v-model="model.address_avenue_one" :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input placeholder="Second Avenue" name="address_avenue_two" :value="model.address_avenue_two"
                      :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="CONTACT NAME" placeholder="Enter contact name" name="contact_name"
                      :value="model.contact_name" :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input placeholder="Enter phone" label="PHONE" name="phone" :value="model.phone"
                      :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input placeholder="Enter email" label="EMAIL" name="email" :value="model.email"
                      :disabled="model.disabled"/>
        </div>
      </div>
    </Form>
    <div class="col-md-12" v-if="(model.action==='summary')">
      <div class="row mt-4">
        <div class="col-md-9">
          <label class="form-control-label">Vendor Purchase Order Report</label>
        </div>
        <div class="col-md-3">
          <Form @submit="downloadFile(model.id)" class="m-2">
            <base-button size="sm" type="info" native-type="submit">
              CSV FILE
            </base-button>
          </Form>
        </div>
      </div>
      <div class="row mt-4 pl-5 pr-5">
        <div class="col-md-3">
          <label class="form-control-label">Total:</label>
        </div>
        <div class="col-md-9">
          <label class="border-summary">{{ formatter(totalSummary) }}</label>
        </div>
      </div>
      <div class="row mt-4 pl-3 pr-5">
        <div class="col-md-12">
          <table class="table table-sm table-striped table-bordered tableC">
            <thead>
            <tr>
              <th scope="col" class="text-center cell-color">SCHOOL</th>
              <th scope="col" class="text-center cell-color">DATE SUBMITTED</th>
              <th scope="col" class="text-center cell-color">UNITS PURCHASED</th>
              <th scope="col" class="text-center cell-color">VALUE</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,idx) in summaryData" :key="'dt'+idx">
              <td class="text-center">{{ item.your_school_name }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">{{ item.amount }}</td>
              <td class="text-center">{{ formatter(item.total_cost) }}</td>
            </tr>
            <tr v-if="summaryData.length===0">
              <td colspan="4" class="text-center">NOT REGISTERS AVAILABLE</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {Form} from "vee-validate";
import * as Yup from "yup";
import VendorService from "../../../services/VendorService";
import swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Drawer",
  components: {
    Form
  },
  data() {
    return {
      model: {
        action: 'save',
        disabled: false,
        email: '',
        phone: '',
        contact_name: '',
        address_avenue_one: '',
        address_avenue_two: '',
        name: '',
        id: '0',
        created_at: null
      },
      drawer: {
        title: '',
        view: false
      },
      form: {
        waiting: false
      },
      schema: null,
      summaryData: [],
      totalSummary: 0
    }
  },
  methods: {
    formatter: function (value) {
      const item = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return item.format(value)
    },
    onSubmit(values, actions) {
      try {
        if (this.model.action === 'save') {
          VendorService.save(values).then(() => {
            actions.resetForm();
            this.emitter.emit('listPaginateVendors')
            this.close()
            swal.fire({
              title: `Vendor created successful`,
            });
          });
        } else {
          VendorService.edit(values.id, values).then(() => {
            actions.resetForm();
            this.emitter.emit('listPaginateVendors')
            this.close()
            swal.fire({
              title: `Vendor saved successful`,
            });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    close() {
      this.initialize()
      this.drawer.view = false;
    },
    summary() {
      this.drawer.title = 'Order Summary';
      this.model.action = 'summary';
      try {

        VendorService.summary(this.model.id).then(async (response) => {
          this.summaryData = await response.data.data;
          this.totalSummary = 0;
          if (this.summaryData.length > 0) {
            this.totalSummary = this.summaryData.reduce(function (accumulator, item) {
              return accumulator + item.total_cost;
            }, 0);
          }
        });
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    info() {
      this.drawer.title = 'See Vendor';
      this.model.action = 'edit';
    },
    add() {
      this.initialize();
      this.drawer.title = 'Add Vendor';
      this.drawer.view = true;
    },
    edit() {
      this.model.disabled = false;
      this.drawer.title = 'Edit Vendor';
      this.model.action = 'edit';
    },
    show(row) {
      this.model.disabled = true;
      this.drawer.title = 'See Vendor';
      this.model.email = row.email;
      this.model.created_at = moment(row.created_at).format('DD-MM-YYYY');
      this.model.phone = row.phone;
      this.model.contact_name = row.contact_name;
      this.model.address_avenue_two = row.address_avenue_two;
      this.model.address_avenue_one = row.address_avenue_one;
      this.model.name = row.name;
      this.model.id = row.id;
      this.drawer.view = true;
      this.model.action = 'show';
    },
    initialize() {
      this.model = {
        disabled: false,
        email: '',
        phone: '',
        contact_name: '',
        address_avenue_one: '',
        address_avenue_two: '',
        name: '',
        id: '0',
        created_at: null,
        action: 'save'
      };
    },
    downloadFile(id) {
      VendorService.csv(id).then(async (response) => {
        const url = await response.data.data;
        var anchor = document.createElement('a');
        anchor.href = url.route;
        anchor.target = "_blank";
        anchor.click();
      });
    }
  },
  created() {
    this.emitter.on('addVendors', () => this.add())
    this.emitter.on('showVendors', (row) => this.show(row))

    this.schema = Yup.object().shape({
      email: Yup.string().email().max(100).required().label("The Email"),
      phone: Yup.string().max(20).required().label("The Phone").typeError("That doesn't look like a phone number"),
      contact_name: Yup.string().max(120).required().label("The Contact Name"),
      address_avenue_two: Yup.string().max(250).required().label("The Second Avenue"),
      address_avenue_one: Yup.string().max(250).required().label("The First Avenue"),
      name: Yup.string().max(120).required().label("The Vendor Name"),

    });

  }
}
</script>

<style>
header#el-drawer__title {
  font-weight: 900 !important;
}

.border-summary {
  font-weight: 900;
  font-size: larger;
  border: 1px solid;
  padding-left: 6px;
  padding-right: 6px;
}

.cell-color {
  background-color: #f6f9fc !important;
  color: #8898aa !important;
}

</style>