<template>
  <el-drawer :title="drawer.title"
             v-model="drawer.view"
             direction="rtl"
             :close-on-press-escape="false"
             :destroy-on-close="true"
             size="50%"
  >

    <div class="row ml-4"  v-if="( model.action!=='save')">
      <Form @submit="info" class="m-2">
        <base-button size="sm" :outline="!(model.action==='edit' || model.action==='show')" type="primary" native-type="submit">
          District Info
        </base-button>
      </Form>
      <Form @submit="summary" class="m-2">
        <base-button size="sm" native-type="submit" :outline="!(model.action==='summary')" type="primary" nati>
          Order Summary
        </base-button>
      </Form>
    </div>

    <Form key="frmDistrict" @submit="onSubmit" :validation-schema="schema" class="pl-4 pr-4" v-if="!(model.action==='summary')">
      <div class="row justify-content-end mb-4">
        <div class="col-md-12 text-right">
        <base-button size="md" outline type="danger" @click="close" v-if="!model.disabled">
          <i class="el el-icon-close"></i>Close
        </base-button>

        <base-button native-type="submit" type="primary" size="md" v-if="!model.disabled"><i
            class="fa fa-save"></i>{{ model.action === 'save' ? 'Create' : 'Save' }}
        </base-button>
        <base-button native-type="button" type="primary" size="md" v-if="model.disabled" @click="edit()"><i
            class="fa fa-edit"></i>Edit
        </base-button>
        </div>
      </div>
      <div class="row" v-if="(model.action==='show' || model.action==='edit'|| model.action==='summary')">
        <div class="col-md-6">
          <base-input
              label="DISTRICT ID" name="id"
              v-model="model.id" :value="model.id" :disabled="true"/>
        </div>
        <div class="col-md-6">
          <base-input
              label="CREATED AT" name="created_at"
              v-model="model.created_at" :value="model.created_at" :disabled="true"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-control-label">STATE NAME</label>
          <el-select
              class="select-primary"
              v-model="model.state_id"
              placeholder="Select state"
              :disabled="model.disabled"
              name="state_id"
          >
            <el-option
                class="select-primary"
                v-for="item in states"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <base-input
              label="DISTRICT NAME" placeholder="Enter District Name" name="name"
              v-model="model.name" :value="model.name" :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="CITY"  placeholder="City" name="city" :value="model.city"
                      v-model="model.city" :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input label="ADDRESS"  placeholder="Address" name="address" :value="model.address"
                      :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="CONTACT NAME" placeholder="Enter contact name" name="supervisor"
                      :value="model.supervisor" :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input placeholder="Enter phone" label="PHONE" name="phone" :value="model.phone"
                      :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input placeholder="Enter email" label="EMAIL" name="email" :value="model.email"
                      :disabled="model.disabled"/>
        </div>
      </div>
    </Form>
    <div class="col-md-12" v-if="(model.action==='summary')">
      <div class="row mt-4">
        <div class="col-md-9">
          <label class="form-control-label">Vendor Purchase Order Report</label>
        </div>
        <div class="col-md-3">
          <Form @submit="csvFile(model.id)" class="m-2">
            <base-button size="sm" type="info" native-type="submit">
              DOWNLOAD
            </base-button>
          </Form>

        </div>
      </div>
      <div class="row mt-4 pl-5 pr-5">
        <div class="col-md-3">
          <label class="form-control-label">Schools quantity:</label>
        </div>
        <div class="col-md-9">
          <label class="border-summary">{{ formatter(totalSummary) }}</label>
        </div>
      </div>
      <div class="row mt-4 pl-3 pr-5">
        <div class="col-md-12">
          <table class="table table-sm table-striped table-bordered">
            <thead>
            <tr>
              <th scope="col" class="text-center cell-color">SKU</th>
              <th scope="col" class="text-center cell-color">ITEM Name</th>
              <th scope="col" class="text-center cell-color">Amount</th>
              <th scope="col" class="text-center cell-color">Total Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,idx) in summaryData" :key="'dt'+idx">
              <td class="text-center">{{ item.code }}</td>
              <td class="text-center">{{ item.name }}</td>
              <td class="text-center">{{ item.amount }}</td>
              <td class="text-center">{{ formatter(item.total_cost) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {Form} from "vee-validate";
import * as Yup from "yup";
import DistrictService from "../../../services/DistrictService";
import swal from "sweetalert2";
import moment from "moment";
import {ElOption, ElSelect} from "element-plus";

export default {
  name: "Drawer",
  components: {
    Form,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      model: {
        action: 'save',
        disabled: false,
        email: '',
        phone: '',
        supervisor: '',
        city: '',
        address: '',
        name: '',
        id: '0',
        created_at: null,
        state_id: null
      },
      drawer: {
        title: '',
        view: false
      },
      form: {
        waiting: false
      },
      schema: null,
      summaryData: [],
      states:[],
      totalSummary: 0
    }
  },
  methods: {
    formatter: function (value) {
      const item=Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return item.format(value)
    },
    onSubmit(values, actions) {
      values.state_id = this.model.state_id;
      if (this.model.action === 'save') {
        DistrictService.save(values).then(() => {
          actions.resetForm();
          this.emitter.emit('listPaginateDistricts')
          this.close()
          swal.fire({
            title: `District created successful`,
          });
        });
      } else {
        DistrictService.edit(values.id, values).then(() => {
          actions.resetForm();
          this.emitter.emit('listPaginateDistricts')
          this.close()
          swal.fire({
            title: `District saved successful`,
          });
        });
      }
    },
    close() {
      this.initialize()
      this.drawer.view = false;
    },
    summary() {
      this.drawer.title = 'Order Summary';
      this.model.action = 'summary';
      DistrictService.summary(this.model.id).then(async (response) => {
        this.summaryData = await response.data.data;
        this.totalSummary = 0;
        if (this.summaryData.length > 0) {
          this.totalSummary = this.summaryData.reduce(function (accumulator, item) {
            return accumulator + item.total_cost;
          }, 0);
        }
      });
    },
    info() {
      this.drawer.title = 'See District';
      this.model.action = 'edit';
    },
    add() {
      this.initialize();
      this.drawer.title = 'Add District';
      this.drawer.view = true;
    },
    edit() {
      this.model.disabled = false;
      this.drawer.title = 'Edit District';
      this.model.action = 'edit';
    },
    show(row) {
      this.model.disabled = true;
      this.drawer.title = 'See District';
      this.model.email = row.email;
      this.model.created_at = moment(row.created_at).format('DD-MM-YYYY');
      this.model.phone = row.phone;
      this.model.supervisor = row.supervisor;
      this.model.address = row.address;
      this.model.city = row.city;
      this.model.name = row.name;
      this.model.id = row.id;
      this.model.state_id = row.state?.id;
      this.drawer.view = true;
      this.model.action = 'show';
    },
    initialize() {
      this.model = {
        disabled: false,
        email: '',
        phone: '',
        supervisor: '',
        city: '',
        address: '',
        name: '',
        id: '0',
        created_at: null,
        action: 'save',
        state: {},
        state_id: null
      };
    },
    csvFile(id) {
      DistrictService.csv(id).then(async (response) => {
        const url = await response.data.data;
        var anchor = document.createElement('a');
        anchor.href =url.route;
        anchor.target="_blank";
        anchor.click();
      });
    }
  },
  created() {
    this.emitter.on('addDistricts', () => this.add());
    this.emitter.on('showDistricts', (row) => this.show(row));
    this.schema = Yup.object().shape({
      email: Yup.string().email().max(100).required().label("The Email"),
      phone: Yup.string().max(20).required().label("The Phone").typeError("That doesn't look like a phone number"),
      supervisor: Yup.string().max(120).required().label("The Contact Name"),
      address: Yup.string().max(250).required().label("The Address"),
      city: Yup.string().max(250).required().label("City"),
      name: Yup.string().max(120).required().label("The District Name"),
      state_id: Yup.number().required().label("The State"),
    });
    DistrictService.listStates().then(async (response) => {
      this.states = response.data.data;
    });
  }
}
</script>

<style>
header#el-drawer__title {
  font-weight: 900 !important;
}

.border-summary {
  font-weight: 900;
  font-size: larger;
  border: 1px solid;
  padding-left: 6px;
  padding-right: 6px;
}

.cell-color {
  background-color: #f6f9fc !important;
  color: #8898aa !important;
}
</style>