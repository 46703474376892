import clientHttp from "../axios";

export default {
    async list(params) {
        return clientHttp.get("/api/vendors", {params: params});
    },
    async delete(id) {
        return clientHttp.delete(`/api/vendors/${id}`);
    },
    async save(params) {
        return clientHttp.post(`/api/vendors`, params);
    },
    async edit(id, params) {
        return clientHttp.put(`/api/vendors/${id}`, params);
    },
    async summary(id) {
        return clientHttp.post(`/api/reports/vendors`, {id})
    },
    async csv(id){
        return clientHttp.post(`/api/csv/vendors`,{id})
    },
    async select(params){
        return clientHttp.get('api/selects/vendors',{params: params})
    }
};
