import {ElNotification} from "element-plus";

function displayError(_error) {
    let contentMsg = '';
    if (typeof _error === 'string' || _error instanceof String) {
        contentMsg = _error
    } else {
        let dataContent = _error.response.data.data.content;
        if (Array.isArray(dataContent)) {
            let msg = "";
            let separator="";
            // eslint-disable-next-line no-unused-vars
            dataContent.forEach(function (item, index) {
                msg += separator+item;
                separator="<br/>";
            });
            contentMsg = msg;
        } else {
            contentMsg = dataContent
        }
    }
    ElNotification({
        type: 'error',
        title: 'Error',
        message: contentMsg,
        dangerouslyUseHTMLString:true
    });
}

function optionsLoading() {
    return {
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgb(23 43 77 / 53%) !important'
    };
}

export {
    optionsLoading,
    displayError
}
