<template>
  <el-drawer :title="drawer.title"
             v-model="drawer.view"
             direction="rtl"
             :close-on-press-escape="false"
             :destroy-on-close="true"
             size="45%">
    <Form @submit="onSubmit" :validation-schema="schema" class="pl-4 pr-4 pb-5">

        <base-input type="hidden" name="id" :value="model.id" :disabled="model.disabled" v-if="model.action!=='save'"/>

      <div class="row justify-content-end mb-4">
        <div class="col-md-12 text-right">
        <base-button icon="el el-icon-close" size="md" outline type="danger" @click="close" v-if="!model.disabled">
          <i class="fas fa-times"></i>Close
        </base-button>

        <base-button native-type="submit" type="primary" size="md" v-if="!model.disabled"><i class="fa fa-save"></i>{{model.action==='save'?'Create':'Save'}}
        </base-button>
        <base-button native-type="button" type="primary" size="md" v-if="model.disabled" @click="edit()"><i class="fas fa-pen"></i>Edit
        </base-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="CREATED AT" name="created_at" :value="model.created_at" :disabled="true" v-if="model.action!=='save'"/>
          <base-input label="CREATED AT" name="created_at" value="---" :disabled="true" v-if="model.action=='save'"/>
        </div>
        <div class="col-md-6">
          <base-input placeholder="Enter name" label="PRODUCT NAME" name="name" :value="model.name" :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input placeholder="Enter product code" label="PRODUCT CODE" name="product_code" :value="model.code" :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <label class="form-control-label">VENDOR</label><br/>
          <el-select placeholder="Single Select" style="width: 100%" ref="selectVendors"  name="vendor" id="vendor"
                     :disabled="model.disabled"
                     filterable
                     v-model="selectVendor"
          >
            <el-option v-for="option in vendors" :disabled="model.disabled"
                       class="select-danger"
                       :value="option.id"
                       :label="option.name"
                       :key="option.name">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <base-input type="number" placeholder="Enter price" label="PRICE" name="price" :value="model.price" :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <base-text-area  label="DESCRIPTION" name="description"
                           placeholder="Enter description" :value="model.description" :disabled="model.disabled"></base-text-area>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-control-label">IMAGE PRODUCT</label>
          <file-input :disabled="model.disabled" :accept="'image/*'"  @change="filesChangeImage"></file-input>
        </div>
        <div class="col-md-6">
          <label class="form-control-label">VIDEO PRODUCT</label>
          <file-input-video  :disabled="model.disabled" @change="filesChangeVideo"></file-input-video>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12" v-for="(item,idx) in model.resources" :key="'resc'+idx">
          <div :style="'width: 100%;height: 300px;background-image: url('+item.path_file+'); background-repeat: no-repeat; background-size: cover;'" v-if="item.type_resource==='photo'"></div>
          <video :id="'video'+idx" style="width:100%;height:300px;" controls=""  v-if="item.type_resource==='video'">
            <source :src="item.path_file" type="video/mp4">
          </video>
          <br/>
        </div>
      </div>
    </Form>
  </el-drawer>
</template>

<script>
import {Form} from "vee-validate";
import * as Yup from "yup";
import ProductService from "../../../services/ProductService";
import swal from "sweetalert2";
import FileInput from "@/components/Inputs/FileInput";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import moment from "moment";
import FileInputVideo from "../../../components/Inputs/FileInputVideo";
import {ElSelect, ElOption} from "element-plus";
import VendorService from "../../../services/VendorService";

export default {
  name: "Drawer",
  components: {
    FileInputVideo,
    Form,
    FileInput,
    BaseTextArea,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      loading:false,
      model: {
        action: 'save',
        disabled: false,
        name: '',
        vendor: '',
        price: '',
        description: null,
        id: '0',
        created_at: '',
        code:'0',
        resources:''
      },
      vendors:[],
      selectVendor:'',
      selectVendorName:'',
      videos: null,
      images: null,
      drawer: {
        title: '',
        view: false
      },
      form: {
        waiting: false
      },
      schema: null,
    }
  },
  methods: {

   remoteVendors(query){
      const context=this;
      VendorService.select({q:query}).then(function (response) {
        context.vendors=response.data.data;
      })
    },
    filesChangeVideo(files) {
      this.videos=files
    },
    filesChangeImage(files) {
      this.images=files
    },
    onSubmit(values, actions) {
      try {
        var formData=new FormData();
        formData.append('name',values.name);
        formData.append('code',values.product_code);
        formData.append('description',values.description);
        formData.append('vendor_id',this.selectVendor);
        formData.append('price',values.price);
        if(this.images!=null){
          formData.append('resources[0][name]',this.images[0])
          formData.append('resources[0][type]','photo')
        }
        if(this.videos!=null){
          formData.append('resources[1][name]',this.videos[0])
          formData.append('resources[1][type]','video')
        }

        if(this.model.action==='save'){
          ProductService.save(formData).then(() => {
            actions.resetForm();
            this.emitter.emit('listPaginateProducts')
            this.close()
            swal.fire({
              title: `Product created successful`,
            });
          });
        }else{
          formData.append('_method','PUT')
          ProductService.edit(values.id,formData).then(() => {
            actions.resetForm();
            this.emitter.emit('listPaginateProducts')
            this.close()
            swal.fire({
              title: `Product saved successful`,
            });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    close() {
      this.initialize()
      this.drawer.view = false;
    },
    info(){
      this.drawer.title = 'See Product';
      this.model.action = 'edit';
    },
    add() {
      this.initialize();
      this.drawer.title = 'Add Product';
      this.drawer.view = true;
    },
    edit() {
      this.model.disabled = false;
      this.drawer.title = 'Edit Product';
      this.model.action = 'edit';
    },
    show(row) {
      this.model.disabled = true;
      this.drawer.title = 'See Products';
      this.model.name = row.p_name;
      this.model.vendor = row.vendor;
      this.model.price = row.price;
      this.model.description = row.description;
      this.model.created_at = moment(row.created_at).format('DD-MM-YYYY HH:mm:ss');
      this.model.id = row.id;
      this.model.code = row.code;
      this.drawer.view = true;
      this.model.action = 'show';
      this.selectVendor =this.model.vendor.id;
      this.model.resources =row.resources;
    },
    initialize() {
      this.model = {
        disabled: false,
        name: '',
        vendor: '',
        description: '',
        price: '0',
        created_at: null,
        code:'0',
        action: 'save',
        resources: []
      };
    },
  },
  created() {
    this.emitter.on('addProducts', () => this.add())
    this.emitter.on('showProducts', (row) => this.show(row))

    this.schema = Yup.object().shape({
      name: Yup.string().max(100).required().label("The Name"),
      vendor: Yup.string().max(100).required().label("The Vendor"),
      product_code: Yup.string().max(100).required().label("The Product Code"),
      price: Yup.string().max(10).required().label("The Contact Name"),
      description: Yup.string().max(500).required().label("The description"),
    });
   this.remoteVendors('');
  }
}
</script>

<style scoped>

.fix-end{
  text-align: right;
}
.el-select{
  width: 100%;
}
.el-input__inner{
  height: 44px!important;
}
</style>