import clientHttp from "../axios";

export default {
    async list(params) {
        return clientHttp.get("/api/products",{params:params});
    } ,
    async delete(id) {
        return clientHttp.delete(`/api/products/${id}`);
    },
    async save(params) {
        return clientHttp.post(`/api/products`,params,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } ,
    async edit(id,params) {
        return clientHttp.post(`/api/products/${id}`,params,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
};
