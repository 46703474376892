<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="header-body">
        <div class="row">
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true"><!---->
              <div class="card-body">
                <div class="row">
                  <div class="col"><h5 class="card-title text-uppercase text-muted mb-0">Total Orders</h5><span
                      class="h2 font-weight-bold mb-0">{{ orders }}</span></div>
                  <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-red"><i
                        class="ni ni-money-coins"></i></div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm text-right"><span
                    class="text-primary mr-2 font-weight-bold"> {{ orders_week }} </span><span
                    class="text-nowrap">Orders last week</span></p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true"><!---->
              <div class="card-body">
                <div class="row">
                  <div class="col"><h5 class="card-title text-uppercase text-muted mb-0">Total Vendors</h5><span
                      class="h2 font-weight-bold mb-0">{{ vendors }}</span></div>
                  <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-orange"><i
                        class="fa fa-user"></i></div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm text-right"><span
                    class="text-success mr-2  font-weight-bold"> {{ vendors_week }} </span><span
                    class="text-nowrap">Vendors input last week</span></p>
              </div><!----><!----></div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true"><!---->
              <div class="card-body">
                <div class="row">
                  <div class="col"><h5 class="card-title text-uppercase text-muted mb-0">Total Districts</h5><span
                      class="h2 font-weight-bold mb-0">{{ districts }}</span></div>
                  <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-green"><i
                        class="fa fa-map-marker"></i></div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm text-right"><span
                    class="text-danger mr-2 font-weight-bold"> {{ districts_month }} </span><span class="text-nowrap">Districts input last month</span>
                </p>
              </div><!----><!----></div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true"><!---->
              <div class="card-body">
                <div class="row">
                  <div class="col"><h5 class="card-title text-uppercase text-muted mb-0">Total Products</h5><span
                      class="h2 font-weight-bold mb-0">{{ products }}</span></div>
                  <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info"><i
                        class="fa fa-cubes"></i></div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm text-right"><span
                    class="text-success mr-2 font-weight-bold"> {{ products_month }} </span><span class="text-nowrap">Products input last month</span>
                </p>
              </div><!----><!----></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="header-body">
       <div class="row">
         <div class="col-md-12">
           <div class="card card-stats mt-4">
             <div id="chart" style="width: 100%;height: 360px"  />
           </div>
         </div>
         <div class="col-md-12">
           <div class="card card-stats mt-4">
             <div id="chart2" style="width: 100%;height: 360px"  />
           </div>
         </div>
       </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import DashboardService from "../../services/DashboardService";
import Apexcharts from "apexcharts";

export default {
  name: "typography",
  components: {
    BaseHeader

  },
  data() {
    return {
      orders: 0,
      products: 0,
      vendors: 0,
      districts: 0,
      orders_week: 0,
      vendors_week: 0,
      products_month: 0,
      districts_month: 0,
      chartOptions:null,
      series: null
    }
  },
  mounted() {
    DashboardService.dashboardCount().then(async (response) => {
      const dataCount = await response.data.data
      this.orders = dataCount?.orders || 0;
      this.products = dataCount?.products || 0;
      this.vendors = dataCount?.vendors || 0;
      this.districts = dataCount?.districts || 0;
      this.orders_week = dataCount?.orders_week || 0;
      this.vendors_week = dataCount?.vendors_week || 0;
      this.products_month = dataCount?.products_month || 0;
      this.districts_month = dataCount?.districts_month || 0;
      this.loadAndCreateChart(dataCount?.matrix_months || [],dataCount?.matrix_totals || [],
          dataCount?.matrix_totals_two || [])


    });
  },
  methods:{
    loadAndCreateChart(categories,_values,_values_two){


      var chart = new Apexcharts(document.querySelector("#chart"), {
        series: [
          {
            name: 'Number of Orders',
            type: 'column',
            data:_values
          }
        ],
        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Orders Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories,
        }
      });
      chart.render();

      var chart2 = new Apexcharts(document.querySelector("#chart2"),  {
        series: [{
          name: 'Amount Price Orders',
          data: _values_two
        }],
        chart: {
          type: 'bar',
          height: 350
        },
        title: {
          text: 'Amount Price of Order by Month',
          align: 'left'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,

          }
        },
        dataLabels: {
          enabled: true,
            formatter: function (value) {
              const item=Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              });
             return item.format(value)
          },
        },
        xaxis: {
          categories,
          labels: {
            formatter: function (value) {
              return "$"+value ;
            }
          },
        },
      });
      chart2.render();


    }
  }
};
</script>
<style>

</style>
