<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
        </div>
        <div class="col-md-8 text-right">
          <base-button size="sm" type="neutral" @click="emitter.emit('addDistricts')">
            <i class="fa fa-plus"></i>Add new district
          </base-button>

        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <table-districts/>
          <drawer></drawer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableDistricts from "./TableDistricts";
import Drawer from "./Drawer";

export default {
  name: "ViewDistricts",
  components: {
    Drawer,
    TableDistricts,
  }
}
</script>

<style scoped>

.form-control, input#created_at {
  height: 43px !important;
}


.has-icon-left .form-control, .has-icon .form-control {
  padding-left: 2.375rem;
  height: 43px;
}

.custom-height {
  height: 43px;
}

.has-icon-left .form-control-feedback, .has-icon .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>