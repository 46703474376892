import clientHttp from "../axios";

export default {
    async list(params) {
        return clientHttp.get("/api/states",{params:params});
    },
    async delete(id) {
        return clientHttp.delete(`/api/states/${id}`);
    },
    async save(params) {
        return clientHttp.post(`/api/states`,params);
    } ,
    async edit(id,params) {
        return clientHttp.put(`/api/states/${id}`,params);
    }
};
