<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
      <route-bread-crumb></route-bread-crumb>
    </nav>

    <ul class="navbar-nav align-items-center ml-md-auto">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" :src="'https://ui-avatars.com/api/?name='+($store.getters['auth/authUser'].name)+'&color=7F9CF5&background=EBF4FF'" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{$store.getters["auth/authUser"].name}}</span>
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Hello, {{$store.getters["auth/authUser"].email}}</h6>
        </div>
        <div class="dropdown-divider"></div>
        <a v-on:click="logout()" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import AuthService from "../../services/AuthService";
import router from "../../router";
import {mapGetters} from "vuex";

export default {
  name: "DashboardNavbar",
  components: {
    BaseNav,
    RouteBreadCrumb
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "SOS Dashboard",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapGetters("auth", ["authUser"]),
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  created (){
    this.emitter.on('clearSearch', () =>{ this.searchQuery="";this.sendSearch()})
  },
  methods: {
    sendSearch(evt){
        this.emitter.emit('sendSearch', evt?.target?.value);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout(){
      try {
        AuthService.logout().then(async()=>{
          await sessionStorage.removeItem('authUser');
            await router.push("/login");
        });
      } catch (error) {
        // prepare(error)
      }

    }
  },
};
</script>
