<template>
  <div class="fix">
    <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
        v-if="tableData"
    >
      <div>
        <div
            class="mb-3 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div>
            <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              @change="listPaginate()"
            >
              <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-input
                type="search"
                class="mb-3 ml-3 mr-3"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
                @change="listPaginate"
            >
            </el-input>

            <base-button v-on:click="clearData()" outline type="primary">Clear <i
                class="fa fa-sync"></i></base-button>
          </div>
        </div>
        <el-table
            ref="selectTable"
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @cell-click="selectionChange"
            highlight-current-row
        >
          <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              v-bind="column"
          >
          </el-table-column>
          <el-table-column min-width="150px" align="center" label="Actions">
            <template v-slot:default="props">
                <base-button
                    @click="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                >
                  <i class="text-white fas fa-trash-alt"></i>
                </base-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template v-slot:footer>
        <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
              class="pagination-no-border"
              v-model="toPage"
              :per-page="pagination.perPage"
              :total="total"
          >
          </base-pagination>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import {ElInput, ElOption, ElSelect, ElTable, ElTableColumn} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import ProductService from "../../../services/ProductService";

export default {
  name: "TableProducts",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      return this.tableData;
    },
    to() {
      return this.pagination.to;
    },
    from() {
      return this.pagination.from;
    },
    total() {
      return this.pagination.total;
    },
  },
  created() {
    this.listPaginate()
        this.emitter.on('listPaginateProducts', () => this.listPaginate())
  },
  data() {
    return {
      toPage: 1,
      tableData: null,
      pagination: {
        perPage: 0,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        from:0,
        to:0
      },
      searchQuery: "",
      propsToSearch: ["name", "vendor.name", "price"],
      tableColumns:  [
        {
          prop: "id",
          label: "PRODUCT ID",
          minWidth: 110,
          align:"center"
        },
        {
          prop: "code",
          label: "SKU",
          minWidth: 100,
          align:"center"
        },   {
          prop: "p_name",
          label: "PRODUCT NAME",
          minWidth: 200,
          align:"center"
        },   {
          prop: "vendor.name",
          label: "VENDOR NAME",
          minWidth: 220,
          align:"center"
        },
        {
          prop: "price",
          label: "PRICE",
          minWidth: 200,
          align:"center"
        },
      ],
      fuseSearch: null,
      searchedData: [],
    };
  },
  methods: {
    clearData() {
      this.searchQuery = "";
      this.listPaginate()
    },
    listPaginate() {
      try {
        ProductService.list({
              'page': this.toPage,
          'per_page':this.pagination.perPage,
              'q':this.searchQuery
            }).then(async (response) => {
          const products = await response.data;
          this.tableData = products.data;
          this.pagination.currentPage = parseInt(products.meta.current_page)|| 0;
          this.pagination.perPage =parseInt(  products.meta.per_page)|| 0;
          this.pagination.total = parseInt( products.meta.total)|| 0;
          this.pagination.to = parseInt( products.meta.to)|| 0;
          this.pagination.from = parseInt( products.meta.from)|| 0;
        });
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
          .fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.value) {
              this.deleteRow(row);
              swalWithBootstrapButtons3.fire({
                title: "Deleted!",
                text: `You deleted ${row.name}`,
              });
            }
          });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        try {
          ProductService.delete(row.id).then(() => {
            this.tableData.splice(indexToDelete, 1);
          });
          // eslint-disable-next-line no-empty
        } catch (error) {
        }
      }
    },
    selectionChange(selectedRows, column) {
      if(column.label!=='Actions'){
        this.selectedRows = selectedRows;
        this.emitter.emit('showProducts', selectedRows)
      }
    },

  },
  watch: {
    toPage: {
      handler: function (value) {
        this.pagination.currentPage = value;
        this.listPaginate()
      }
    }
  }
}
</script>

<style scoped>
  
</style>