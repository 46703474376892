import clientHttp from "../axios";

export default {
    async list(params) {
        return clientHttp.get("/api/orders",{params:params});
    },
    async delete(id) {
        return clientHttp.delete(`/api/orders/${id}`);
    },
    async save(params) {
        return clientHttp.post(`/api/districts`,params);
    } ,
    async edit(id,params) {
        return clientHttp.put(`/api/orders/${id}`,params);
    },
    async pdf(id){
        return clientHttp.post(`/api/pdf/order`,{id});
    },
    async districts(){
        return clientHttp.get(`/api/selects/districtsSupervisor`);
    },
    async products(){
        return clientHttp.get(`/api/selects/extendedProducts`);
    },
    deleteProduct(orderDetail){
        return clientHttp.delete(`/api/detail/${orderDetail}/order`);
    },
    addProduct(order, params) {
        return clientHttp.post(`/api/order/${order}/add-item`, params);
    }
};
