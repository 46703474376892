
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from 'mitt';
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = { containerClassName: "ct-notification" };

import {ElDrawer } from 'element-plus';

const emitter = mitt()

const appInstance = createApp(App);
appInstance.config.warnHandler = () => null;
appInstance.config.globalProperties.emitter = emitter
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
appInstance.use(ElDrawer);
appInstance.use(store);
appInstance.provide('emitter', emitter);
appInstance.mount("#app");

