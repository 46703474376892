import store from "../store/index";
import auth from "../middleware/auth";
import guest from "../middleware/guest";
import middlewarePipeline from "../router/middlewarePipeline";


import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Layout/AuthLayout";
import Typography from "@/views/Components/Home.vue";
import Orders from "@/views/Components/Orders/Orders.vue";
import Districts from "@/views/Components/Districts/Districts";
import States from "@/views/Components/States/States";

// Tables pages
import RegularTables from "../views/Tables/RegularTables.vue";
import SortableTables from "../views/Tables/SortableTables.vue";
import PaginatedTables from "../views/Tables/PaginatedTables.vue";
import Vendors from "../views/Components/Vendors/Vendors";
import Products from "../views/Components/Products/Products";
import Login from "../views/Components/Auth/Login";

import {createRouter, createWebHashHistory} from "vue-router";


const routes = [
    {
        path: "/tables",
        component: DashboardLayout,
        redirect: "/tables/regular",
        name: "Tables menu",
        children: [
            {
                meta: { middleware: [auth] },
                path: "regular",
                name: "Tables",
                components: {default: RegularTables},
            },
            {
                meta: { middleware: [auth] },
                path: "sortable",
                name: "Sortable",
                components: {default: SortableTables},
            },
            {
                meta: { middleware: [auth] },
                path: "paginated",
                name: "Paginated",
                components: {default: PaginatedTables},
            },
        ],
    },
    {
        meta: { middleware: [auth] },
        path: "/orders",
        redirect: "/orders",
        component: DashboardLayout,
        name: "Orders",
        children: [
            {
                path: "/orders",
                name: "Orders",
                components: {default: Orders},
            },
        ]
    },
    {
        meta: { middleware: [auth] },
        path: "/states",
        redirect: "/states",
        component: DashboardLayout,
        name: "States",
        children: [
            {
                meta: { middleware: [auth] },
                path: "/states",
                name: "States",
                components: {default: States},
            },
        ]
    },
    {
        meta: { middleware: [auth] },
        path: "/districts",
        redirect: "/districts",
        component: DashboardLayout,
        name: "Districts",
        children: [
            {
                meta: { middleware: [auth] },
                path: "/districts",
                name: "Districts",
                components: {default: Districts},
            },
        ]
    },
    {
        meta: { middleware: [auth] },
        path: "/vendors",
        redirect: "/vendors",
        component: DashboardLayout,
        name: "Vendors",
        children: [
            {
                path: "/vendors",
                name: "Vendors",
                components: {default: Vendors},
            },
        ]
    },
    {
        meta: { middleware: [auth] },
        path: "/products",
        redirect: "/products",
        component: DashboardLayout,
        name: "Products",
        children: [
            {
                path: "/products",
                name: "Products",
                components: {default: Products},
            },
        ]
    },
    {
        meta: { middleware: [auth] },
        path: "/",
        redirect: "home",
        component: DashboardLayout,
        name:"Home",
        children: [
            {
                path: "/home",
                name: "Home",
                components: { default: Typography },
            },
        ],
    },
    {
        meta: { middleware: [guest] },
        path: "/",
        redirect: "login",
        component: AuthLayout,
        children: [
            {
                path: "/login",
                name: "login",
                components: { default: Login },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: "active",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});


router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router;

