<template>
  <el-drawer :title="drawer.title"
             v-model="drawer.view"
             direction="rtl"
             :close-on-press-escape="false"
             :destroy-on-close="true"
             :before-close="close"
             size="55%"
  >
    <div class="row ml-4" v-if="( model.action!=='save')">
      <Form @submit="pdfFile(model.id)" class="m-2">
        <base-button size="sm" type="info" native-type="submit">
          <i class="fas fa-download"></i>
          Download PDF
        </base-button>
      </Form>
    </div>

    <Form @submit="onSubmit" :validation-schema="schema" class="pl-4 pr-4">
      <base-input type="hidden" name="id" :value="model.id+''" :disabled="model.disabled" v-if="model.action!=='save'"/>
      <div class="row justify-content-end mb-4">
        <div class="col-md-12 text-right">
          <base-button icon="el el-icon-close" size="md" outline type="danger" @click="close" v-if="!model.disabled">
            <i class="fas fa-times"></i>Close
          </base-button>

          <base-button native-type="submit" type="primary" size="md" v-if="!model.disabled"><i
              class="fa fa-save"></i>{{ model.action === 'save' ? 'Create' : 'Save' }}
          </base-button>
          <base-button native-type="button" type="primary" size="md" v-if="model.disabled" @click="edit()"><i
              class="fas fa-pen"></i>Edit
          </base-button>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="CREATED BY" name="created_by_name" :value="model.created_by_name"  :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input label="CREATED BY EMAIL" name="created_by_email" :value="model.created_by_email"
                      :disabled="model.disabled"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="CREATED BY PHONE" name="created_by_phone" :value="model.created_by_phone"
                      :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input label="CREATED AT" name="created_at" :value="model.created_at" :disabled="true"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-control-label">DISTRICT</label><br/>
          <el-select name="district_id" placeholder="Single Select" style="width: 100%" ref="selectDistricts"
                     id="district_id"
                     :disabled="model.disabled"
                     filterable
                     v-model="selectDistrict"
                     @change="getManager"
          >
            <el-option v-for="option in districts" :disabled="model.disabled"
                       class="select-danger"
                       :value="option.id"
                       :label="option.name"
                       :key="option.name">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <label class="form-control-label">DISTRICT MANAGER</label>
          <input class="form-control" type="text" name="district_manager" v-model="selectDistrictManager"
                 :disabled="true"/>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <base-input type="text" placeholder="Enter current price" label="YOUR SCHOOL NAME" name="your_school_name"
                      :value="model.your_school_name" :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input type="text" placeholder="Enter school address" label="SCHOOL ADDRESS" name="school_address"
                      :value="model.school_address" :disabled="model.disabled"/>
        </div>


      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input-number placeholder="Enter current price" label="GRANT" name="grant" :value="model.grant"
                             :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input-number placeholder="Enter free rate and reduce lunch rate" label="FREE RATE AND REDUCE LUNCH RATE" name="free_rate_and_reduce_lunch_rate" :value="model.free_rate_and_reduce_lunch_rate"
                             :disabled="model.disabled"/>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input-number placeholder="Enter number students" label="NUMBER STUDENTS" name="number_students" :value="model.number_students"
                             :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input-number placeholder="Enter current fulltime nurses" label="CURRENT FULLTIME NURSES" name="current_fulltime_nurses"
                             :value="model.current_fulltime_nurses"
                             :disabled="model.disabled"/>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input-number placeholder="Enter existing nurses offices" label="EXISTING NURSES OFFICES" name="existing_nurses_offices" :value="model.existing_nurses_offices"
                             :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input-number placeholder="Enter offices required notification" label="OFFICES REQUIRED NOTIFICATION" name="offices_required_notification"
                             :value="model.offices_required_notification"
                             :disabled="model.disabled"/>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input-number placeholder="Enter stadiums or events areas" label="STADIUMS OR EVENTS AREAS" name="stadiums_or_events_areas"
                             :value="model.stadiums_or_events_areas"
                             :disabled="model.disabled"/>
        </div>
        <div class="col-md-6">
          <base-input-number placeholder="Enter number of seats" label="NUMBER OF SEATS" name="numbers_of_seats"
                             :value="model.numbers_of_seats"
                             :disabled="model.disabled"/>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input-number placeholder="Enter numbers of events per year" label="NUMBERS OF EVENTS PER YEAR" name="numbers_of_events_per_year"
                             :value="model.numbers_of_events_per_year"
                             :disabled="model.disabled"/>
        </div>

      </div>

      <div class="col-md-12">

        <div class="row mt-4">
          <div class="col-md-12">
            <table class="table table-sm table-striped table-bordered" style="width:100%;">
              <thead>
              <tr>
                <th scope="col" class="text-center cell-color">ACTIONS</th>
                <th scope="col" class="text-center cell-color">ITEM</th>
                <th scope="col" class="text-center cell-color">ITEMNAME</th>
                <th scope="col" class="text-center cell-color">QUANTITY</th>
                <th scope="col" class="text-center cell-color">UNIT PRICE</th>
                <th scope="col" class="text-center cell-color">PRICE</th>
              </tr>
              </thead>
              <tbody>


              <tr v-for="(item,idx) in summaryData" :key="'dt'+idx">
                <td class="text-center center-row">
                  <base-button
                    @click="handleDelete(idx, summaryData[idx])"
                    :disabled="model.disabled"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    v-if="summaryData[idx] && summaryData[idx].id"
                  >
                    <i class="text-white fas fa-trash-alt"></i>
                  </base-button>
                  <base-button
                    @click="saveNewProduct(summaryData[idx])"
                    :disabled="model.disabled || !summaryData[idx].select_prod || !summaryData[idx].quantity"
                    class="btn-link"
                    type="success"
                    size="sm"
                    icon
                    v-else
                  >
                    <i class="text-white fa fa-save"></i>
                  </base-button>
                </td>
                <td class="text-center">
                  <select :disabled="model.disabled" class="form-control" v-model="summaryData[idx].select_prod"
                          @change="changeValues(idx)">
                    <option :value="prod.id" v-for="(prod , ixd) in selectProducts" :key="'it_'+ixd">{{ prod.code }}
                    </option>
                  </select>


                </td>
                <td class="text-center">
                  <input type="text" :key="'prd_name_'+idx" readonly v-model="summaryData[idx].product_name"
                         class="form-control"/>
                </td>
                <td class="text-center">
                  <input type="text" :key="'quantity_'+idx" :disabled="model.disabled"
                         v-model="summaryData[idx].quantity" @keyup="changeValues(idx)" class="form-control"/>
                </td>
                <td class="text-center"><input :key="'unit_price'+idx" readonly class="form-control"
                                               v-model="summaryData[idx].unit_price"/></td>
                <td class="text-center"><input :key="'price'+idx" readonly class="form-control"
                                               v-model="summaryData[idx].price"/></td>
                
              </tr>
              <tr key="tbTotal">
                <td class="text-right" colspan="5"><b>TOTAL:</b></td>
                <td class="text-center">{{ formatter(totalSummary || model.order_pride || 0) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Form>
  </el-drawer>
</template>

<script>
import {Form} from "vee-validate";
import * as Yup from "yup";
import OrderService from "../../../services/OrderService";
import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver"
import {ElSelect, ElOption} from "element-plus";
import BaseInputNumber from "../../../components/Inputs/BaseInputNumber";

export default {
  name: "Drawer",
  components: {
    Form,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    BaseInputNumber
  },
  data() {
    return {
      selectDistrict: '',
      selectDistrictManager: '',
      model: {
        id: '0',
        action: 'save',
        disabled: false,
        district_id: null,
        your_school_name: null,
        school_address: null,
        created_by_name: null,
        created_by_email: null,
        created_by_phone: null,
        free_rate_and_reduce_lunch_rate: 0,
        number_students: 0,
        current_fulltime_nurses: 0,
        existing_nurses_offices: 0,
        offices_required_notification: 0,
        stadiums_or_events_areas: 0,
        numbers_of_seats: 0,
        numbers_of_events_per_year: 0,
        order_price: 0,
        grant: 0,
        created_at: null,
        district_manager: null,
        district: null,
        schema: null,
      },
      summaryData: [],
      totalSummary: 0,
      products: [],
      selectProducts: [],
      districts: [],
      drawer: {
        title: '',
        view: false
      },
      form: {
        waiting: false
      },
      schema: null,
      isSaving: false
    }
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
  methods: {
    formatter: function (value) {
      const item = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return item.format(value)
    },
    changeValues(idx) {
      if(this.summaryData[idx].quantity < 1) {
        this.summaryData[idx].quantity = 1;
      }
      const itemSearch = (this.selectProducts.find(x => x.id === this.summaryData[idx].select_prod));
      this.summaryData[idx].product_name = itemSearch?.name;
      this.summaryData[idx].product_id = itemSearch?.id;
      this.summaryData[idx].unit_price = itemSearch?.price;
      this.summaryData[idx].price = itemSearch?.price * this.summaryData[idx].quantity;
      this.totalSummary = 0;
      if (this.summaryData.length > 0) {
        this.totalSummary = this.summaryData.reduce(function (accumulator, item) {
          return accumulator + item.price;
        }, 0);
      }
    },
    getManager(id) {
      this.selectDistrictManager = (this.districts.find(x => x.id === id))?.supervisor
    },
    pdfFile(id) {
      OrderService.pdf({id}).then(async (response) => {
        const fileData = await response.data;
        var byteCharacters = atob(fileData);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {type: "application/pdf"});

        FileSaver.saveAs(blob, "OrderDetail_" + id + ".pdf");
      });
    },
    summary() {
      this.model.action = 'summary';

    },
    onSubmit(values, actions) {
      try {
        values.district_id = this.selectDistrict;
        values.details = [];
        this.summaryData.forEach((item) => {
          let dataItem = item;
          if(item && item.product){
            dataItem.product_name = item.product.name;
            dataItem.select_prod = item.product.id;
            if (dataItem.product_name.length > 32) {
              dataItem.product_name = (item?.product?.name.substring(0, 30) + '...');
            }
            values.details.push({
              product_id: dataItem.product_id,
              quantity: dataItem.quantity,
            });
          }
        });
      if (this.model.action === 'save') {
          OrderService.save(values).then(() => {
            actions.resetForm();
            this.emitter.emit('listPaginateOrders')
            this.close()
            swal.fire({
              title: `Order created successful`,
            });
          });
        } else {
          OrderService.edit(values.id, values).then(() => {
            actions.resetForm();
            this.emitter.emit('listPaginateOrders');
            this.close();
            swal.fire({
              title: `Order saved successful`,
            });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    close() {
      this.initialize();
      this.drawer.view = false;
      this.emitter.emit('listPaginateOrders');
    },
    info() {
      this.drawer.title = 'Purchase order Info';
      this.model.action = 'edit';
    },
    add() {
      this.initialize();
      this.drawer.title = 'Purchase order Info';
      this.drawer.view = true;
    },
    edit() {
      this.model.disabled = false;
      this.drawer.title = 'Edit Purchase order Info';
      this.model.action = 'edit';
    },
    show(row) {
      this.model.disabled = true;
      this.drawer.title = 'See Orders';
      this.model.created_by_name = row.created_by_name
      this.model.created_by_email = row.created_by_email
      this.model.created_by_phone = row.created_by_phone
      this.model.created_at = moment(row.created_at).format('DD-MM-YYYY HH:mm:ss');
      this.model.district_id = row.district_id
      this.model.district_manager = row.district_manager
      this.model.your_school_name = row.your_school_name
      this.model.grant = parseInt(row.grant)
      this.model.school_address = row.school_address
      this.model.district = row.district;
      this.model.id = row.id;
      this.drawer.view = true;
      this.model.action = 'show';


      this.model.free_rate_and_reduce_lunch_rate = parseInt(row.free_rate_and_reduce_lunch_rate);
      this.model.number_students = parseInt(row.number_students);
      this.model.current_fulltime_nurses = parseInt(row.current_fulltime_nurses);
      this.model.existing_nurses_offices = parseInt(row.existing_nurses_offices);
      this.model.offices_required_notification = parseInt(row.offices_required_notification);
      this.model.stadiums_or_events_areas = parseInt(row.stadiums_or_events_areas);
      this.model.numbers_of_seats = parseInt(row.numbers_of_seats);
      this.model.numbers_of_events_per_year = parseInt(row.numbers_of_events_per_year);

      const context = this;
      context.summaryData = [];
      row.details.forEach(function (item) {
        var dataItem = item;
        dataItem.product_name = item.product.name;
        dataItem.select_prod = item.product.id;
        if (dataItem.product_name.length > 32) {
          dataItem.product_name = (item?.product?.name.substring(0, 30) + '...');
        }
        context.summaryData.push(dataItem);
      });
      this.totalSummary = 0;
      if (this.summaryData.length > 0) {
        this.totalSummary = this.summaryData.reduce(function (accumulator, item) {
          return accumulator + item.price;
        }, 0);
      }
      this.selectDistrict = this.model.district.id;
      this.selectDistrictManager = this.model.district.supervisor;
      context.summaryData.push({});
    },
    initialize() {
      this.model = {
        id: '0',
        action: 'save',
        disabled: false,
        district_id: null,
        your_school_name: null,
        school_address: null,
        created_by_name: null,
        created_by_email: null,
        created_by_phone: null,
        free_rate_and_reduce_lunch_rate: 0,
        number_students: 0,
        current_fulltime_nurses: 0,
        existing_nurses_offices: 0,
        offices_required_notification: 0,
        stadiums_or_events_areas: 0,
        numbers_of_seats: 0,
        numbers_of_events_per_year: 0,
        order_price: 0,
        grant: 0,
        created_at: null,
        district_manager: null,
        district: null,
        schema: null,
      };
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
          .fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.value) {
              this.deleteRow(row);
              swalWithBootstrapButtons3.fire({
                title: "Deleted!",
                text: `You deleted successfully`,
              });
            }
          });
    },
    deleteRow(row) {
      let indexToDelete = this.summaryData.findIndex(
          (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        OrderService.deleteProduct(row.id).then(() => {
          this.summaryData.splice(indexToDelete, 1);
        });
      }
    },
    saveNewProduct(row) {
      if(!this.isSaving){
        this.isSaving = true;
        const params = {
          product_id: row.product_id,
          quantity: row.quantity
        };
        OrderService.addProduct(this.model.id, params).then((response) => {
          row.id = response.data.data.id;
          this.summaryData.push({});
          this.isSaving = false;
        });
      }
    }
  },
  created() {
    this.emitter.on('showOrders', (row) => this.show(row))

    this.schema = Yup.object().shape({
      your_school_name: Yup.string().max(100).required().label("The Your School Name"),
      grant: Yup.number().min(0).required().label("The Grant"),
      school_address: Yup.string().max(100).required().label("The school address"),
      free_rate_and_reduce_lunch_rate: Yup.number().min(0).required().label("The free rate and reduce lunch rate").typeError('You must specify a number'),
      number_students: Yup.number().min(0).required().label("The number students").typeError('You must specify a number'),
      current_fulltime_nurses: Yup.number().min(0).required().label("The current fulltime nurses").typeError('You must specify a number'),
      existing_nurses_offices: Yup.number().min(0).required().label("The existing nurses offices").typeError('You must specify a number'),
      offices_required_notification: Yup.number().min(0).required().label("The offices required notification").typeError('You must specify a number'),
      stadiums_or_events_areas: Yup.number().min(0).required().label("The stadiums or events areas").typeError('You must specify a number'),
      numbers_of_seats: Yup.number().min(0).required().label("The numbers of seats").typeError('You must specify a number'),
      numbers_of_events_per_year: Yup.number().min(0).required().label("The numbers of events per year").typeError('You must specify a number'),

    });

    OrderService.districts().then((response) => {
      this.districts = response.data.data;
    });
    OrderService.products().then((response) => {
      this.selectProducts = response.data.data;
    });
  }
}
</script>

<style>
.fix-end {
  text-align: right;
}

.el-select {
  width: 100%;
}

.el-input__inner {
  height: 44px !important;
}

.center-row {
  vertical-align: middle!important
}

.swal2-container {
  z-index: 10000 !important;
}

</style>